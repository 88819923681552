import React, { FC } from "react";
import { Col } from "antd";

import "./style.css";

interface IHeaderInfo {
  workshopId: string;
  currentStep: number;
  isOwn: "own" | "carker" | undefined;
}

export const HeaderInfo: FC<IHeaderInfo> = ({
  workshopId,
  currentStep,
  isOwn,
}) => {
  return (
    <Col className="reception-header-info">
      <h2>Recepción del vehículo</h2>
      <p>Datos de recepción</p>
      <div className="dots-container">
        <span
          className={`${
            currentStep >= (isOwn == "own" ? 0 : 0) ? "active" : ""
          }`}
        />
        <span
          className={`${
            currentStep >= (isOwn == "own" ? 1 : 1) ? "active" : ""
          }`}
        />
        <span
          className={`${
            currentStep >= (isOwn == "own" ? 2 : 4) ? "active" : ""
          }`}
        />
      </div>
    </Col>
  );
};
