import React, { FC, useEffect, useState } from "react";

import { Form, Input, FormInstance, Checkbox } from "antd";

import { FooterRender } from "../";

import { CkCheckbox, CkInput } from "../../../../../../CkUI";
import "./style.css";

interface IItem {
  name: string;
  desc: string;
}

interface IInventory {
  InventoryForm: FormInstance;
  // Controll actions
  nextSlide: Function;
  prevSlide: Function;
  // isReadOnly
  isReadOnly: boolean;
  // Custom
  title: string;
  list: IItem[];
  next: number;
  back: number;
  isComment?: boolean;
  isOwn?: string;
  // createFormReception?: Function;
  setExitModal?: Function;
  // Info
  workshopId: string;
  currentStep: number;
  // Set Info
}

interface ICheckItem {
  isReadOnly: boolean;

  name: string;
  desc: string;
}

interface IComment {
  isReadOnly: boolean;
}

const { TextArea } = Input;

const CheckItem: FC<ICheckItem> = ({
  isReadOnly,

  name,
  desc,
}) => {
  return (
    <CkCheckbox
      name={name}
      valuePropName="checked"
      checkboxProps={{
        disabled: isReadOnly,
      }}
    >
      {desc}
    </CkCheckbox>
  );
};

const Comment: FC<IComment> = ({ isReadOnly }) => {
  return (
    <CkInput
      name="AccesoryAdditionalDetails"
      extra="Otros elementos a reportar en el inventario del vehículo:"
      className="input-regular-extra"
      rules={[
        {
          required: false,
          message: "Agregar comentarios adicionales",
        },
      ]}
      inputProps={{
        // className: "textarea-size",
        disabled: isReadOnly,
        placeholder: "Agregar comentarios adicionales",
        // rows: 1
      }}
    />
  );
};

export const Inventory: FC<IInventory> = ({
  InventoryForm,
  // Controll actions
  nextSlide,
  prevSlide,
  // isReadOnly
  isReadOnly,
  // Custom
  title,
  list,
  next,
  back,
  isComment,
  isOwn,
  // createFormReception,
  setExitModal,
  // Info
  workshopId,
  currentStep,
}) => {
  const [all, setAll] = useState<boolean>(false);

  const completeForm = (data: any) => {
    if (isOwn && isOwn == "carker" && setExitModal && currentStep == 7) {
      setExitModal(true);
    } else if (isOwn && isOwn == "own" && setExitModal && currentStep == 5) {
      setExitModal(true);
    } else {
      nextSlide(next);
    }
  };

  const isSubmitDisabled = () => {
    return true;
  };

  const allTrueValuesMatch = (list: any[], values: any) => {
    return list.every((item) => {
      if (!values.hasOwnProperty(item.value)) return true;

      return values[item.value] === true;
    });
  };

  const handleAllChange = (e: any) => {
    const isChecked = e.target.checked;

    list.map((item: any) => {
      InventoryForm.setFieldValue(item.value, isChecked);
    });
  };

  return (
    <div className="inventory-container">
      <Form
        form={InventoryForm}
        initialValues={{ ...InventoryForm.getFieldsValue() }}
        onFinish={(e) => completeForm(e)}
        onReset={() => prevSlide(back)}
        onChange={() => {
          const formValues = InventoryForm.getFieldsValue();
          setAll(allTrueValuesMatch(list, formValues));
        }}
      >
        <div className="reception-step-resize">
          <div className="sub-reception-step">
            <p className="bold">{title}</p>

            <div
              className={[
                "inv-sub-container",
                isComment ? "is-comment" : "",
              ].join(" ")}
            >
              {!isComment && !isReadOnly && (
                <CkCheckbox
                  checkboxProps={{
                    className: "--custom special-blue",
                    checked: all,
                    onChange: (e) => handleAllChange(e),
                    disabled: isReadOnly,
                  }}
                >
                  Seleccionar todos
                </CkCheckbox>
              )}

              {!isComment &&
                list.map((item) => (
                  <CheckItem
                    isReadOnly={isReadOnly}
                    //@ts-ignore
                    name={item.value}
                    desc={item.desc}
                  />
                ))}

              {isComment && <Comment isReadOnly={isReadOnly} />}
            </div>
          </div>

          {FooterRender(isOwn, currentStep, isReadOnly, isSubmitDisabled)}
        </div>
      </Form>
    </div>
  );
};
