import React, { FC, useEffect, useState } from "react";

import { Form, Radio, FormInstance } from "antd";

import { OwnFormat } from "./OwnFormat";
import { CarKerFormat } from "./CarKerFormat";
import { FooterRender } from "../";

import { CkRadio } from "../../../../../../CkUI";
import "./style.css";

interface IPhysicalInspection {
  PhysicalInspectionForm: FormInstance;
  // Controll actions
  nextSlide: Function;
  prevSlide: Function;
  // isReadOnly
  isReadOnly: boolean;
  // Info
  isOwn: "own" | "carker" | undefined;
  workshopId: string;
  currentStep: number;
  // Set Info
  setIsOwn: Function;
  sliderValue: number;
  setSliderValue: Function;
  appointmentTracking: any;
  sasToken: string;
  handleResetAttachments: Function;
}

const emptyDefaultFile = {
  file: undefined,
  fileList: [],
};

function filterByUID(
  arr1: Array<{ file: { uid: string } }>,
  arr2: Array<{ uid: string }>
): Array<{ uid: string }> {
  return arr2.filter((item2) =>
    arr1.some((item1) => item1.file.uid === item2.uid)
  );
}

export const PhysicalInspection: FC<IPhysicalInspection> = ({
  PhysicalInspectionForm,
  // Controll actions
  nextSlide,
  prevSlide,
  // isReadOnly
  isReadOnly,
  // Info
  isOwn,
  workshopId,
  currentStep,
  // Set Info
  setIsOwn,
  sliderValue,
  setSliderValue,
  appointmentTracking,
  sasToken,
  handleResetAttachments,
}) => {
  const [ownUploadList, setOwnUploadList] = useState<any[]>([]);
  const [carkerUploadList, setCarkerUploadList] = useState<any[]>([]);

  useEffect(() => {
    if (isOwn !== "own") {
      setOwnUploadList([]);
    }
  }, [isOwn]);

  const completeForm = async (data: any) => {
    await updateAttForm();
    nextSlide(2);
  };

  const updateAttForm = async () => {
    const uploadTemp =
      isOwn == "own" ? [...ownUploadList] : [...carkerUploadList];
    const docs =
      isOwn == "own"
        ? PhysicalInspectionForm.getFieldValue("document")
        : PhysicalInspectionForm.getFieldValue("carkerDocuments");

    if (docs && docs.fileList) {
      const result = {
        file: docs.file,
        fileList: await filterByUID(uploadTemp, docs.fileList),
      };

      if (isOwn == "own") {
        PhysicalInspectionForm.setFieldValue("document", result);
      } else if (isOwn == "carker") {
        PhysicalInspectionForm.setFieldValue("carkerDocuments", result);
      }
    }
  };

  const isSubmitDisabled = () => {
    if (!isReadOnly) {
      const values = PhysicalInspectionForm.getFieldsValue();
      if (values.formatType == undefined) return false;

      if (values.formatType == "own" && ownUploadList.length == 0) return false;

      return true;
    } else {
      return true;
    }
  };

  return (
    <div className="physical-inspection-container">
      <Form
        form={PhysicalInspectionForm}
        initialValues={{
          formatType: PhysicalInspectionForm.getFieldValue("formatType"),
          document:
            PhysicalInspectionForm.getFieldValue("document") ||
            emptyDefaultFile,
          carkerDocuments:
            PhysicalInspectionForm.getFieldValue("carkerDocuments") ||
            emptyDefaultFile,
          fuelLevel:
            PhysicalInspectionForm.getFieldValue("fuelLevel") >= 0
              ? PhysicalInspectionForm.getFieldValue("fuelLevel")
              : 50,
        }}
        onFinish={(e) => completeForm(e)}
        onReset={() => prevSlide(0)}
      >
        <div className="reception-step-resize">
          <div className="sub-reception-step">
            <p className="bold title">Detalles encontrados en el vehículo</p>

            <Form.Item
              className="class-formatType"
              name="formatType"
              rules={[
                {
                  required: isReadOnly ? false : true,
                  message: "Seleccione un tipo de formato",
                },
              ]}
              extra="¿Quieres subir tu propio formato de recepción o completar el que proporciona CarKer?"
            >
              <Radio.Group
                disabled={isReadOnly}
                onChange={(e) => {
                  setIsOwn(e.target.value);
                  handleResetAttachments();
                }}
              >
                <Radio.Button disabled={isReadOnly} value="own">
                  Subir mi propio formato
                </Radio.Button>
                <Radio.Button disabled={isReadOnly} value="carker">
                  Usar el formato de CarKer
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {isOwn == "own" && (
              <OwnFormat
                uploadList={ownUploadList}
                setUploadList={setOwnUploadList}
                //
                isReadOnly={isReadOnly}
                name={"document"}
                form={PhysicalInspectionForm}
                appointmentTracking={appointmentTracking}
                sasToken={sasToken}
              />
            )}
            {isOwn == "carker" && (
              <CarKerFormat
                uploadList={carkerUploadList}
                setUploadList={setCarkerUploadList}
                //
                isReadOnly={isReadOnly}
                name={"carkerDocuments"}
                form={PhysicalInspectionForm}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                appointmentTracking={appointmentTracking}
                sasToken={sasToken}
              />
            )}
          </div>

          <Form.Item
            style={{ height: "1px", padding: "0px", margin: "0px" }}
            name="document"
          ></Form.Item>

          {FooterRender(isOwn, currentStep, isReadOnly, isSubmitDisabled)}
        </div>
      </Form>
    </div>
  );
};
