import React, { FC, useState, useCallback, useEffect } from "react";

import {
  Form,
  Upload,
  Image as ImageView,
  Button,
  Input,
  FormInstance,
  Row,
} from "antd";

import { RcFile, UploadProps as TUploadProps } from "antd/es/upload/interface";

import { PaperClipOutlined } from "@ant-design/icons";
import { TrashIcon } from "../../../../../../../assets/SvgIcons";
import { CkMessage } from "../../../../../../../CkUI";

interface IOwnFormat {
  uploadList: any[];
  setUploadList: Function;

  isReadOnly: boolean;
  name: string;
  form: FormInstance;
  appointmentTracking: any;
  sasToken: string;
}

interface IFile {
  uid: string;
  name: string;
  status: string;
  file: any;
  fileName?: string;
  photoUrl?: string;
  preview?: string;
}

const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

interface PDFViewerProps {
  pdfUrl: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ pdfUrl }) => {
  return (
    <div style={{ height: "auto" }}>
      <iframe
        title="PDF Viewer"
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
          pdfUrl
        )}&embedded=true`}
        width="100%"
        height="300px"
        frameBorder="1"
      ></iframe>
    </div>
  );
};

export const OwnFormat: FC<IOwnFormat> = ({
  uploadList,
  setUploadList,

  isReadOnly,
  name,
  form,
  appointmentTracking,
  sasToken,
}) => {
  // const [uploadList, setUploadList] = useState<any[]>([]);

  const updateArray = useCallback((element) => {
    let copy = [...uploadList];
    copy = element;
    setUploadList(copy);
  }, []);

  function isPDF(fileName: string) {
    const extension = fileName.split(".").pop();

    return extension.toLowerCase() === "pdf";
  }

  const uploadProps: TUploadProps = {
    name: "file",
    showUploadList: false,
    multiple: false,
    accept: "image/*,.pdf",
    beforeUpload: async (file: RcFile): Promise<any> => {
      if (!file.type.startsWith("image/") && file.type !== "application/pdf") {
        CkMessage({
          type: "error",
          text: "Solo se soportan imágenes y documentos PDF.",
        });
        return Upload.LIST_IGNORE;
      }

      const fileSizeInMB = file.size / 1024 / 1024;

      if (fileSizeInMB > 21) {
        CkMessage({
          type: "error",
          text: "El archivo excede el límite de 20MB.",
        });
        return Upload.LIST_IGNORE;
      }

      if (uploadList.length == 5) {
        CkMessage({
          type: "error",
          text: "El máximo de archivos adjuntos para esta sección es 5",
        });
        return Upload.LIST_IGNORE;
      }

      const preview = await getBase64(file);
      const item = [...uploadList];
      item.push({
        uid: item.length.toString(),
        name: file.name,
        status: "done",
        file: file,
        preview: preview,
      });
      updateArray(item);
    },
  };

  const FilePreview: FC<{
    file: IFile;
    // fileType: "image" | "video";
    fileType: string;
    index: number;
  }> = ({ file, fileType, index }) => {
    const deleteFile = () => {
      let selectedCard = [...uploadList];
      selectedCard = selectedCard.filter((f) => f.uid !== file.uid);
      updateArray(selectedCard);

      let docs = form.getFieldValue(["document", "fileList"]);
      docs = docs.filter((f: any) => f.uid !== file.file.uid);

      if (docs.length > 0) {
        form.setFieldValue("document", { file: {}, fileList: docs });
      } else {
        form.setFieldValue("document", undefined);
      }
    };
    return (
      <figure className={`reception-document-preview ${fileType}`}>
        {fileType.startsWith("image/") && (
          <ImageView
            className="document-view"
            src={file.preview}
            preview={{
              zIndex: 6000,
            }}
          />
        )}
        {fileType === "application/pdf" && (
          <iframe
            className="document-view-pdf"
            src={file.preview}
            style={{ width: "100%", height: "100%" }}
            frameBorder={1}
          />
        )}
        <TrashIcon
          className="trashico-white"
          style={{ color: "var(--primary-color)" }}
          onClick={deleteFile}
        />
      </figure>
    );
  };

  return (
    <>
      <p className="regular" style={{ fontSize: "12px" }}>
        Si cuentas con un formato de recepción propio de tu taller, puedes
        subirlo aquí.
      </p>

      <p className="bold" style={{ fontSize: "12px" }}>
        Formatos aceptados: <span>Imagen, PDF;</span>
      </p>

      {!isReadOnly && (
        <Form.Item
          className={[
            ...["class-formatDoc"],
            uploadList.length == 8 ? ["resize"] : [],
          ].join(" ")}
          name="document"
          rules={[
            {
              required: isReadOnly ? false : true,
              message: "Formato requerido",
            },
          ]}
        >
          {uploadList.length <= 7 && (
            <Upload
              disabled={isReadOnly}
              {...uploadProps}
              className={[
                ...["upload-document"],
                uploadList.length == 8 ? ["transparent"] : [],
              ].join(" ")}
            >
              <Button
                disabled={isReadOnly}
                className="upload-botton"
                icon={<PaperClipOutlined />}
              >
                Adjuntar formato de recepción
              </Button>
            </Upload>
          )}
        </Form.Item>
      )}

      {/* <div className="reception-multiple-document">
        {uploadList.map((file: any, idx: number) => (
          <FilePreview
            file={file}
            fileType={file.file.type}
            index={idx}
            key={`file-preview-${idx}`}
          />
        ))}
      </div> */}

      <div className="reception-once-document">
        {!isReadOnly &&
          uploadList.map((file: any, idx: number) => (
            <FilePreview
              file={file}
              fileType={file.file.type}
              index={idx}
              key={`file-preview-${idx}`}
            />
          ))}

        {isReadOnly &&
          appointmentTracking.trackingPhotos
            .filter(
              (item: any) => item.filePosition >= 5 && item.filePosition <= 9
            )
            .map((item: any) =>
              isPDF(item.fileName) ? (
                <PDFViewer pdfUrl={`${item.photoUrl}?${sasToken}`} />
              ) : (
                <>
                  <ImageView
                    className="readonly-image-own"
                    src={`${item.photoUrl}?${sasToken}`}
                    preview={{
                      zIndex: 6000,
                    }}
                  />
                  <br />
                </>
              )
            )}
      </div>
    </>
  );
};
