import React, { FC, useState, useRef, useEffect, useCallback } from "react";

import { Image as ImageView, Card, FormInstance } from "antd";

import { DeleteOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";

import carRight from "../../../../../../../assets/Images/vehiculo-carro-derecha.jpg";
import carLeft from "../../../../../../../assets/Images/vehiculo-carro-izquierda.jpg";
import carFront from "../../../../../../../assets/Images/vehiculo-carro-frente.jpg";
import carBack from "../../../../../../../assets/Images/vehiculo-carro-trasera.jpg";
import vanRight from "../../../../../../../assets/Images/vehiculo-carga-derecho.jpg";
import vanLeft from "../../../../../../../assets/Images/vehiculo-carga-izquierdo.jpg";
import vanFront from "../../../../../../../assets/Images/vehiculo-carga-frente.jpg";
import vanBack from "../../../../../../../assets/Images/vehiculo-carga-trasera.jpg";
import bikeRight from "../../../../../../../assets/Images/vehiculo-moto-derecha.jpg";
import bikeLeft from "../../../../../../../assets/Images/vehiculo-moto-izquierda.jpg";
import bikeFront from "../../../../../../../assets/Images/vehiculo-moto-frente.jpg";
import bikeBack from "../../../../../../../assets/Images/vehiculo-moto-trasera.jpg";

import vectorDraws from "../../vectorDraws";
import { CkIcon } from "../../../../../../../CkUI";
import { isMobile, iOS } from "../../../../../../Utilities";

interface Coords {
  x: number;
  y: number;
  type: string;
}

type TCarSide = "front" | "back" | "left" | "right";

const carSides = ["right", "left", "front", "back"];

type TVehicleType = "AUTOS" | "VEHICULOS DE CARGA LIGERA" | "MOTOCICLETA";

interface IUploadAttachmentOutput {
  fileName: string;
  photoUrl: string;
}

const sideImages = {
  AUTOS: {
    right: carRight,
    left: carLeft,
    front: carFront,
    back: carBack,
  },
  "VEHICULOS DE CARGA LIGERA": {
    right: vanRight,
    left: vanLeft,
    front: vanFront,
    back: vanBack,
  },
  MOTOCICLETA: {
    right: bikeRight,
    left: bikeLeft,
    front: bikeFront,
    back: bikeBack,
  },
};

export const Canvas: FC<{
  PhysicalDetailsForm: FormInstance;
  carTypeCode: TVehicleType;
  carSide: TCarSide;
  setCarSide: Function;
  vehicleFace: number;
  currentStep: number;
}> = ({
  PhysicalDetailsForm,
  carTypeCode,
  carSide,
  setCarSide,
  vehicleFace,
  currentStep,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasCircleRef = useRef<HTMLCanvasElement>(null);
  const canvasLineRef = useRef<HTMLCanvasElement>(null);
  const canvasMirrorRef = useRef<HTMLCanvasElement>(null);
  const canvasColorRef = useRef<HTMLCanvasElement>(null);

  const isDown = useRef<boolean>(false);
  const dragTarget = useRef<Coords | null>(null);
  const startX = useRef<number | null>(null);
  const startY = useRef<number | null>(null);
  const drawings = useRef<any[]>([]);

  // const [carSide, setCarSide] = useState<TCarSide>("front");
  const [erase, setErase] = useState<boolean>(true);
  const [trigger, setTrigger] = useState<boolean>(true);

  const [newDrawing, setNewDrawing] = useState<string>("");
  const [DrawingsRight, setDrawingsRight] = useState<any[]>([]);
  const [DrawingsLeft, setDrawingsLeft] = useState<any[]>([]);
  const [DrawingsFront, setDrawingsFront] = useState<any[]>([]);
  const [DrawingsBack, setDrawingsBack] = useState<any[]>([]);

  const [vehicleTypeSelected, setVehicleTypeSelected] =
    useState<TVehicleType>(carTypeCode);
  // useState<TVehicleType>("AUTOS");

  const [currentSideImage, setCurrentSideImage] = useState<string>(
    sideImages[carTypeCode]["front"]
    // sideImages["AUTOS"]["front"]
  );

  useEffect(() => {
    draw();
  }, [DrawingsRight, DrawingsLeft, DrawingsFront, DrawingsBack]);

  useEffect(() => {
    if (canvasCircleRef.current === null) return;
    const ctx = canvasCircleRef.current.getContext("2d")!;
    vectorDraws.circle(ctx, 3, 3);
  }, [canvasCircleRef]);

  useEffect(() => {
    if (canvasLineRef.current === null) return;
    const ctx = canvasLineRef.current.getContext("2d")!;
    vectorDraws.line(ctx, 3, 3);
  }, [canvasLineRef]);

  useEffect(() => {
    if (canvasColorRef.current === null) return;
    const ctx = canvasColorRef.current.getContext("2d")!;
    vectorDraws.color(ctx, 3, 3);
  }, [canvasColorRef]);

  useEffect(() => {
    if (canvasMirrorRef.current === null) return;
    const ctx = canvasMirrorRef.current.getContext("2d")!;
    vectorDraws.mirror(ctx, 3, 3);
  }, [canvasMirrorRef]);

  useEffect(() => {
    setCurrentSideImage(getSideImage(carSide));
  }, [carSide, vehicleTypeSelected]);

  useEffect(() => {
    effectCarSide();
  }, [vehicleFace]);

  useEffect(() => {
    updateCarFileList();
  }, [DrawingsRight, DrawingsLeft, DrawingsFront, DrawingsBack]);

  const updateCarFileList = async () => {
    const images: any[] | undefined = await handleSavePictures();
    PhysicalDetailsForm.setFieldValue("carFileList", images);
  };

  const getIndex = () => {
    switch (carSide) {
      case "right":
        return DrawingsRight.findIndex((a) => a === dragTarget.current);
      case "left":
        return DrawingsLeft.findIndex((a) => a === dragTarget.current);
      case "front":
        return DrawingsFront.findIndex((a) => a === dragTarget.current);
      case "back":
        return DrawingsBack.findIndex((a) => a === dragTarget.current);
      default:
        return -1;
    }
  };

  /**
   * Loop throught current drawings and return a boolean
   * indicating if given coordinates are inside a draw shape
   * The shape targeted is setted in dragTarget ref
   */
  const hitbox = (coords: { x: number; y: number }): boolean => {
    const drawings = getSelectedDrawings();
    const inspect = drawings.some((draw: Coords) => {
      if (
        coords.x >= draw.x - 32 &&
        coords.x <= draw.x + 32 &&
        coords.y >= draw.y - 32 &&
        coords.y <= draw.y + 32
      ) {
        dragTarget.current = draw;
        return true;
      } else {
        return false;
      }
    });
    return inspect;
  };

  const effectCarSide = () => {
    switch (vehicleFace) {
      case 1:
        return handleCarSide("front");
      case 2:
        return handleCarSide("right");
      case 3:
        return handleCarSide("back");
      case 4:
        return handleCarSide("left");
      default:
        return handleCarSide("front");
    }
  };

  const getSideImage = (selected: TCarSide): string => {
    if (selected === undefined || vehicleTypeSelected === undefined) return "";
    const image = sideImages[vehicleTypeSelected][selected];
    return image;
  };

  const getSelectedDrawings = (selected?: string) => {
    switch (selected || carSide) {
      case "right":
        return [...DrawingsRight];
      case "left":
        return [...DrawingsLeft];
      case "front":
        return [...DrawingsFront];
      case "back":
        return [...DrawingsBack];
      default:
        return [];
    }
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();
    if (
      canvasRef.current === null ||
      canvasRef.current === undefined ||
      newDrawing
    )
      return null;
    drawings.current = getSelectedDrawings();
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * canvas.width;
    const y = ((e.clientY - rect.top) / rect.width) * canvas.width;
    const hit = hitbox({ x, y });
    if (hit) {
      const index = getIndex();
      if (index < 0) return null;
      const temp = getSelectedDrawings();
      temp.splice(index, 1);
      setDrawings(temp);
      drawings.current = temp;
      draw();
    }
  };

  const setSelectedDrawings = (draw: Coords) => {
    switch (carSide) {
      case "right":
        setDrawingsRight([...DrawingsRight, draw]);
        drawings.current = [...DrawingsRight, draw];
        break;
      case "left":
        setDrawingsLeft([...DrawingsLeft, draw]);
        drawings.current = [...DrawingsLeft, draw];
        break;
      case "front":
        setDrawingsFront([...DrawingsFront, draw]);
        drawings.current = [...DrawingsFront, draw];
        break;
      case "back":
        setDrawingsBack([...DrawingsBack, draw]);
        drawings.current = [...DrawingsBack, draw];
        break;
      default:
    }
  };

  const getClickPosition = (e: any) => {
    e.preventDefault();
    if (canvasRef.current === null || canvasRef.current === undefined)
      return null;
    if (!erase) {
      handleContextMenu(e);
    } else {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * canvas.width;
      const y = ((e.clientY - rect.top) / rect.width) * canvas.width;
      if (newDrawing) {
        setSelectedDrawings({ x, y, type: newDrawing });
        setNewDrawing("");
        draw();
      } else {
        startX.current = x;
        startY.current = y;
        isDown.current = hitbox({ x, y });
      }
    }
  };

  const setDrawings = (draws: any[]) => {
    switch (carSide) {
      case "right":
        setDrawingsRight(draws);
        break;
      case "left":
        setDrawingsLeft(draws);
        break;
      case "front":
        setDrawingsFront(draws);
        break;
      case "back":
        setDrawingsBack(draws);
        break;
      default:
    }
  };

  const getCanvas = (): CanvasRenderingContext2D | null =>
    canvasRef.current === null ? null : canvasRef.current.getContext("2d")!;

  const clearCanvas = () => {
    if (canvasRef.current === null) return;
    const ctx = canvasRef.current.getContext("2d")!;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  const draw = (referenceDrawings?: any[]) => {
    clearCanvas();
    const useThisDrawings = referenceDrawings || drawings.current;
    const ctx = getCanvas();
    if (ctx === null) return;
    useThisDrawings.forEach((draw: Coords) => {
      switch (draw.type) {
        case "circle":
          vectorDraws.circle(ctx, draw.x - 32, draw.y - 32);
          break;
        case "color":
          vectorDraws.color(ctx, draw.x - 32, draw.y - 32);
          break;
        case "line":
          vectorDraws.line(ctx, draw.x - 32, draw.y - 32);
          break;
        case "mirror":
          vectorDraws.mirror(ctx, draw.x - 32, draw.y - 32);
          break;
        default:
      }
    });
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (
      isDown.current === null ||
      canvasRef.current === null ||
      dragTarget.current === null
    )
      return;
    drawings.current = getSelectedDrawings();
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * canvas.width;
    const y = ((e.clientY - rect.top) / rect.width) * canvas.width;
    const dx = x - (startX.current || 0);
    const dy = y - (startY.current || 0);
    startX.current = x;
    startY.current = y;
    dragTarget.current.x += dx;
    dragTarget.current.y += dy;
    draw();
  };

  const handleMouseUp = (e: any) => {
    dragTarget.current = null;
    isDown.current = false;

    setTimeout(() => {
      updateCarFileList();
    }, 2000);
  };

  const handleDelete = (e: any) => {
    if (dragTarget.current !== null) {
      const index = getIndex();
      if (index < 0) return null;
      const temp = getSelectedDrawings();
      temp.splice(index, 1);
      setDrawings(temp);
      drawings.current = temp;
      draw();
    }
    handleMouseUp(e);
  };

  const handleCarSide = (selected: TCarSide) => {
    setCarSide(selected);
    drawings.current = getSelectedDrawings(selected);
    draw();
  };

  const handleNewDrawing = (type: string) => {
    if (newDrawing === type) {
      setNewDrawing("");
    } else {
      setNewDrawing(type);
    }
  };

  const handleSavePictures = async () => {
    const canvas = canvasRef.current;
    if (canvas === null) return;

    const ctx = getCanvas();
    if (ctx === null) return;
    const images: any[] = [];
    await carSides.map(async (side, index) => {
      const image = new Image();
      const drawings = getSelectedDrawings(side);
      image.onload = function () {
        draw(drawings);
        ctx.globalCompositeOperation = "destination-over";
        ctx.drawImage(image, 0, 0);
        images.push({
          url: canvas.toDataURL("image/jpeg"),
          FileName: `${side}-side-vehicle`,
          FilePosition: index + 1,
        });
        ctx.globalCompositeOperation = "source-over";
        /**
         * This line of code is necessary to make sure that
         * the canva remains with the last selected side and draws
         * so if the user see again the canva, everything matchs
         * if you remove it, you will face a (not so much)beautiful bug
         */
        draw();
      };
      image.src = getSideImage(side as TCarSide);
      return image;
    });
    return images;
  };

  return (
    <>
      <div className="switch-container">
        <div
          onClick={() => setErase((prev) => !prev)}
          className="switch-sub-container"
        >
          <span
            id="add-element"
            className={[erase ? ["active-element"] : []].join(" ")}
          >
            <PlusOutlined /> Agregar elemento
          </span>
          <span
            id="delete-element"
            className={[!erase ? ["active-element"] : []].join(" ")}
          >
            <MinusOutlined /> Borrar elemento
          </span>
        </div>
      </div>

      <p
        className="bold"
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0px",
        }}
      >
        Da clic sobre un ícono para seleccionarlo y luego sobre la imagen del
        vehículo para agregarlo o borrarlo
      </p>

      <div className="canva-group-container">
        <div className="canva-container">
          <CkIcon
            className={newDrawing === "circle" ? "active" : "disabled"}
            name="reception-hit"
            onClick={() => handleNewDrawing("circle")}
          >
            <canvas ref={canvasCircleRef} width={70} height={70} />
          </CkIcon>
          <p>Golpe</p>
        </div>
        <div className="canva-container">
          <CkIcon
            className={newDrawing === "color" ? "active" : "disabled"}
            name="reception-uncolored"
            onClick={() => handleNewDrawing("color")}
          >
            <canvas ref={canvasColorRef} width={70} height={70} />
          </CkIcon>
          <p>Decoloración</p>
        </div>
        <div className="canva-container">
          <CkIcon
            className={newDrawing === "line" ? "active" : "disabled"}
            name="reception-scratch"
            onClick={() => handleNewDrawing("line")}
          >
            <canvas ref={canvasLineRef} width={70} height={70} />
          </CkIcon>
          <p>Rayón</p>
        </div>
        <div className="canva-container">
          <CkIcon
            className={newDrawing === "mirror" ? "active" : "disabled"}
            name="reception-mirror"
            onClick={() => handleNewDrawing("mirror")}
          >
            <canvas ref={canvasMirrorRef} width={70} height={70} />
          </CkIcon>
          <p>Espejo</p>
        </div>
      </div>

      <Card title={null} className="vehicle-issues-card">
        {erase && !isMobile() && !iOS() && (
          <DeleteOutlined
            className="delete"
            style={{
              top: window.screen.width > 996 ? "15px" : "5px",
              right: window.screen.width > 996 ? "15px" : "5px",
            }}
            onMouseMove={handleMouseMove}
            onMouseUp={handleDelete}
          />
        )}
        <canvas
          className="vehicle-issues"
          onMouseDown={getClickPosition}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onContextMenu={handleContextMenu}
          width={500}
          height={350}
          ref={canvasRef}
          style={{
            // backgroundImage: `url(${getSideImage()})`,
            backgroundImage: `url(${currentSideImage})`,
          }}
        ></canvas>
      </Card>

      <p
        className="bold"
        style={{
          width: "100%",
          textAlign: "center",
          margin: "10px 0px 0px 0px",
        }}
      >
        Si no hay nada que reportar, puedes ir al siguiente paso
      </p>
    </>
  );
};
