import React, { FC, useState, useEffect, useCallback } from "react";

import {
  Form,
  Upload,
  Button,
  Image as ImageView,
  Input,
  Divider,
  Slider,
  FormInstance,
} from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { RcFile, UploadProps as TUploadProps } from "antd/es/upload/interface";

import { TrashIcon } from "../../../../../../../assets/SvgIcons";
import BoardExample from "../../../../../../../assets/Images/BoardExample.png";

import { CkInput, CkMessage } from "../../../../../../../CkUI";

interface ICarKerFormat {
  uploadList: any[];
  setUploadList: Function;

  isReadOnly: boolean;
  name: string;
  form: FormInstance;

  sliderValue: number;
  setSliderValue: Function;
  appointmentTracking: any;
  sasToken: string;
}

interface IFile {
  uid: string;
  name: string;
  status: string;
  file: any;
  fileName?: string;
  photoUrl?: string;
  preview?: string;
}

interface IFuelRender {
  isReadOnly: boolean;
  sliderValue: number;
  setSliderValue: Function;
  updateFormValue: Function;
}

const sonDivider = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const formatter = (value: any) => `${value}%`;

const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const FuelRender: FC<IFuelRender> = ({
  isReadOnly,
  sliderValue,
  setSliderValue,
  updateFormValue,
}) => {
  return (
    <Form.Item
      className={`class-field ${isReadOnly ? "read-only" : ""}`}
      name="fuelLevel"
      rules={[
        {
          required: isReadOnly ? false : true,
          message: "Nivel de combustible requerido",
        },
      ]}
      extra="Nivel de combustible"
    >
      <div className="fuel-container">
        <div
          className="fuel-background"
          style={{ width: `${sliderValue}%` }}
        ></div>
        <div className="fuel-lines">
          <Divider className="divider-father" type="vertical" />
          {sonDivider.map((res) => {
            return (
              <Divider
                className="divider-son"
                type="vertical"
                style={{ color: "red" }}
              />
            );
          })}
          <Divider
            className="divider-father"
            type="vertical"
            style={{ color: "red" }}
          />
          {sonDivider.map((res) => {
            return (
              <Divider
                className="divider-son"
                type="vertical"
                style={{ color: "red" }}
              />
            );
          })}
          <Divider
            className="divider-father"
            type="vertical"
            style={{ color: "red" }}
          />
        </div>
        <Slider
          className="fuel-slider"
          tipFormatter={formatter}
          marks={{
            0: "E",
            50: "1/2",
            100: "F",
          }}
          tooltip={{ open: true }}
          defaultValue={50}
          onChange={(e: any) => {
            setSliderValue(e);
            updateFormValue(e);
          }}
          disabled={isReadOnly}
        />
      </div>
    </Form.Item>
  );
};

export const CarKerFormat: FC<ICarKerFormat> = ({
  uploadList,
  setUploadList,
  //
  isReadOnly,
  name,
  form,
  sliderValue,
  setSliderValue,
  appointmentTracking,
  sasToken,
}) => {
  // const [uploadList, setUploadList] = useState<any[]>([]);

  const updateArray = useCallback((element) => {
    let copy = [...uploadList];
    copy = element;
    setUploadList(copy);
    // form.setFieldValue("carkerDocuments", [...element]);
  }, []);

  const updateFormFuelValue = (value: number) => {
    form.setFieldValue("fuelLevel", value);
  };

  const uploadProps: TUploadProps = {
    name: "file",
    showUploadList: false,
    multiple: false,
    accept: "image/*",
    beforeUpload: async (file: RcFile): Promise<any> => {
      if (!file.type.startsWith("image/")) {
        CkMessage({type: "error", text: "Solo se soportan imágenes."});
        return Upload.LIST_IGNORE;
      }

      const fileSizeInMB = file.size / 1024 / 1024;

      if (fileSizeInMB > 21) {
        CkMessage({type: "error", text: "El archivo excede el límite de 20MB."});
        return Upload.LIST_IGNORE;
      }

      if (uploadList.length == 5) {
        CkMessage({
          type: "error",
          text: "El máximo de archivos adjuntos para esta sección es 5"
        });
        return Upload.LIST_IGNORE;
      }

      const preview = await getBase64(file);
      const item = [...uploadList];
      item.push({
        uid: item.length.toString(),
        name: file.name,
        status: "done",
        file: file,
        preview: preview,
      });
      updateArray(item);
    },
  };

  const FilePreview: FC<{
    file: IFile;
    // fileType: "image" | "video";
    fileType: string;
    index: number;
  }> = ({ file, fileType, index }) => {
    const deleteFile = () => {
      let selectedCard = [...uploadList];
      selectedCard = selectedCard.filter((f) => f.uid !== file.uid);
      updateArray(selectedCard);

      let docs = form.getFieldValue(["carkerDocuments", "fileList"]);
      docs = docs.filter((f: any) => f.uid !== file.file.uid);

      if (docs.length > 0) {
        form.setFieldValue("carkerDocuments", { file: {}, fileList: docs });
      } else {
        form.setFieldValue(["carkerDocuments", "fileList"], []);
      }
    };
    return (
      <figure className={`reception-document-preview ${fileType}`}>
        {fileType.startsWith("image/") && (
          <ImageView
            className="document-view"
            src={file.preview}
            preview={{
              zIndex: 6000,
            }}
          />
        )}
        {fileType === "application/pdf" && (
          <iframe
            className="document-view-pdf"
            src={file.preview}
            style={{ width: "100%", height: "100%" }}
            frameBorder={1}
          />
        )}
        <TrashIcon
          className="trashico-white"
          style={{ color: "var(--primary-color)" }}
          onClick={deleteFile}
        />
      </figure>
    );
  };

  return (
    <>
      <p className="regular" style={{ margin: isReadOnly ? "0px" : "10px" }}>
        Sube una o más fotos del tablero del automóvil donde se pueda ver el
        kilometraje y el nivel del combustible.
      </p>

      {!isReadOnly && (
        <div className="example-board">
          <p className="regular">Ejemplo:</p>
          <img style={{ borderRadius: "10px" }} src={BoardExample} />
        </div>
      )}

      {!isReadOnly && (
        <Form.Item
          className={[
            ...["class-formatCarkerDoc"],
            uploadList.length < 8 ? ["resize"] : [],
          ].join(" ")}
          name="carkerDocuments"
        >
          {uploadList.length < 8 && (
            <Upload
              disabled={isReadOnly}
              {...uploadProps}
              className={[
                ...["upload-document"],
                uploadList.length < 8 ? ["transparent"] : [],
              ].join(" ")}
            >
              <Button
                disabled={isReadOnly}
                className="upload-botton"
                icon={<PictureOutlined />}
              >
                Adjuntar fotos
              </Button>
            </Upload>
          )}
        </Form.Item>
      )}

      <div className="reception-multiple-document">
        {!isReadOnly &&
          uploadList.map((file: any, idx: number) => (
            <FilePreview
              file={file}
              fileType={file.file.type}
              index={idx}
              key={`file-preview-${idx}`}
            />
          ))}

        {isReadOnly &&
          appointmentTracking.trackingPhotos
            .filter(
              (item: any) => item.filePosition >= 10 && item.filePosition <= 14
            )
            .map((item: any) => (
              <ImageView
                rootClassName="readonly-image-small"
                src={`${item.photoUrl}?${sasToken}`}
                preview={{
                  zIndex: 6000,
                }}
              />
            ))}
      </div>

      <p className="regular">
        Si no puedes cargar una imagen, completa la información en los siguiente
        campos.
      </p>

      <div className="row-gap-between">
        <CkInput
          name="Mileage"
          extra="Kilometraje"
          className="class-field"
          rules={[
            {
              required: false,
              message: "Kilometraje requerido",
            },
            {
              max: 7,
              message: "El kilometraje no puede tener más de 7 caracteres",
            },
          ]}
          inputProps={{
            disabled: isReadOnly,
            placeholder: "Escribe el kilometraje",
            type: "number",
            addonAfter: <p>km</p>,
          }}
        />

        <FuelRender
          isReadOnly={isReadOnly}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          updateFormValue={updateFormFuelValue}
        />
      </div>
    </>
  );
};
