import React, { FC, useEffect } from "react";
import { Form, Button, Col, message, Input, Image } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";

import { getUser, getSasToken } from "../../../../../../stores/selectors";
import defaultAvatar from "../../../../../../assets/Images/avatar placeholder.svg";
import avatar from "../../../../../../assets/Images/avatar-coworker-placeholder.svg";

import { FooterRender } from "../";
import moment from "moment";

import { CkIcon } from "../../../../../../CkUI";

import "./style.css";

interface IReceptionData {
  nextSlide: Function;
  prevSlide: Function;

  isReadOnly: boolean;

  isOwn: string | undefined;
  appointmentInfo: any;
  workshopId: string;
  currentStep: number;

  coworkersItems: any[];
  workshopStaff: string;
  setWorkshopStaff: Function;
}

export const ReceptionData: FC<IReceptionData> = ({
  nextSlide,
  prevSlide,

  isReadOnly,

  isOwn,
  appointmentInfo,
  workshopId,
  currentStep,

  coworkersItems,
  workshopStaff,
  setWorkshopStaff,
}) => {
  const user = useSelector(getUser);
  const sasToken: string = useSelector(getSasToken);

  const [ReceptionDataForm] = Form.useForm();

  const completeForm = () => {
    nextSlide(1);
  };

  const isSubmitDisabled = () => {
    return true;
  };

  function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="reception-data-container">
      <Form
        form={ReceptionDataForm}
        initialValues={{
          // workshopStaff: user ? user!.id : undefined,
          workshopStaff: workshopStaff,
        }}
        onFinish={() => completeForm()}
        onReset={() => prevSlide(0)}
      >
        <div className="reception-step-resize">
          <div className="sub-reception-step">
            <p className="regular title" style={{ marginBottom: "20px" }}>
              Verifica y/o completa los datos para generar la recepción del
              vehículo
            </p>

            <div className="date-info-container">
              <div className="col-info">
                <p className="blue">Fecha y hora de recepción</p>
                <p className="regular">
                  {capitalizeFirstLetter(
                    moment(appointmentInfo.startDateTime).format(
                      "dddd DD/MM/YYYY [a las] HH:mm"
                    )
                  )}
                </p>
              </div>

              <div className="col-info">
                <p className="blue">Vehículo</p>
                <p className="bold">
                  {appointmentInfo.vehicle.brandCode || ""}{" "}
                  {appointmentInfo.vehicle.modelCode || ""}
                </p>
                <p className="regular">
                  {appointmentInfo.vehicle.yearCode || ""}
                </p>
              </div>
            </div>

            {user && (
              <div className="staff-container">
                <p className="bold">
                  Personal del taller que hace la recepción
                </p>
                {coworkersItems.map((item) => (
                  <div
                    className={`staff-card ${
                      workshopStaff == item.value ? "active" : ""
                    } ${isReadOnly ? "read-only" : ""}`}
                    onClick={() => {
                      if (!isReadOnly) setWorkshopStaff(item.value);
                    }}
                  >
                    <Image
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      preview={false}
                      src={
                        sasToken && item?.photoUrl
                          ? `${item?.photoUrl}?${sasToken}`
                          : avatar
                      }
                      fallback={avatar}
                    />

                    <div className="name-icon">
                      <CkIcon name="checkmark" />
                      <span className="name">{item.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {FooterRender(isOwn, currentStep, isReadOnly, isSubmitDisabled)}
        </div>
      </Form>
    </div>
  );
};
