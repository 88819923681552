export const InternalInventory = {
  title: "Inventario interno",
  list: [
    {
      name: "radio",
      desc: "Radio",
      value: "RADIO",
    },
    {
      name: "displayTab",
      desc: "Pantalla tab",
      value: "PANTALLA",
    },
    {
      name: "antenna",
      desc: "Antena",
      value: "ANTENA",
    },
    {
      name: "firstAidKit",
      desc: "Botiquín",
      value: "BOTIQUIN",
    },
    {
      name: "cleaners",
      desc: "Limpiadores",
      value: "LIMPIADOR",
    },
    {
      name: "frontMats",
      desc: "Tapetes delanteros",
      value: "TAPETE1",
    },
    {
      name: "rearMats",
      desc: "Tapetes traseros",
      value: "TAPETE2",
    },
  ],
};

export const TrunkInventory = {
  title: "Inventario cajuela",
  list: [
    {
      name: "extinguisher",
      desc: "Extintor",
      value: "EXTINTOR",
    },
    {
      name: "triangles",
      desc: "Triángulos",
      value: "TRIANGULOS",
    },
    {
      name: "spareTire",
      desc: "Llanta de refacción",
      value: "LLANTA",
    },
    {
      name: "jumperCables",
      desc: "Cables pasacorriente",
      value: "PASACORRIENTE",
    },
    {
      name: "tools",
      desc: "Herramientas",
      value: "HERRAMIENTAS",
    },
    {
      name: "cat",
      desc: "Gato",
      value: "GATO",
    },
  ],
};

export const GloveBoxInventory = {
  title: "Inventario guantera",
  list: [
    {
      name: "circulationCard",
      desc: "Tarjeta de circulación",
      value: "TARJETACIRCULACION",
    },
    {
      name: "check",
      desc: "Verificación",
      value: "VERIFICACION",
    },
    {
      name: "servicesLog",
      desc: "Bitácora de servicios",
      value: "BITACORASERVICIOS",
    },
    {
      name: "manual",
      desc: "Manual",
      value: "MANUAL",
    },
    {
      name: "insurancePolicy",
      desc: "Póliza de seguro",
      value: "POLIZA",
    },
  ],
};
