import React, { FC, useEffect, useState, useCallback } from "react";

import {
  Form,
  Radio,
  FormInstance,
  Upload,
  Button,
  Image as ImageView,
} from "antd";
import { RcFile, UploadProps as TUploadProps } from "antd/es/upload/interface";
import {
  LeftOutlined,
  RightOutlined,
  PictureOutlined,
} from "@ant-design/icons";

import carFront from "../../../../../../assets/Images/vehiculo-carro-frente.jpg";
import carBack from "../../../../../../assets/Images/vehiculo-carro-trasera.jpg";
import carRight from "../../../../../../assets/Images/vehiculo-carro-derecha.jpg";
import carLeft from "../../../../../../assets/Images/vehiculo-carro-izquierda.jpg";

import vanRight from "../../../../../../assets/Images/vehiculo-carga-derecho.jpg";
import vanLeft from "../../../../../../assets/Images/vehiculo-carga-izquierdo.jpg";
import vanFront from "../../../../../../assets/Images/vehiculo-carga-frente.jpg";
import vanBack from "../../../../../../assets/Images/vehiculo-carga-trasera.jpg";

import bikeRight from "../../../../../../assets/Images/vehiculo-moto-derecha.jpg";
import bikeLeft from "../../../../../../assets/Images/vehiculo-moto-izquierda.jpg";
import bikeFront from "../../../../../../assets/Images/vehiculo-moto-frente.jpg";
import bikeBack from "../../../../../../assets/Images/vehiculo-moto-trasera.jpg";

import { TrashIcon } from "../../../../../../assets/SvgIcons";

import { Canvas } from "./Canvas";
import { FooterRender } from "../";

import { CkMessage } from "../../../../../../CkUI";
import "./style.css";

interface IPhysicalDetails {
  PhysicalDetailsForm: FormInstance;
  // Controll actions
  nextSlide: Function;
  prevSlide: Function;
  // isReadOnly
  isReadOnly: boolean;
  // Custom
  title: string;
  next: number;
  back: number;
  willWash: boolean;
  isWash?: boolean;
  setIsWash?: Function;
  // Info
  isOwn: string | undefined;
  workshopId: string;
  currentStep: number;
  appointmentTracking: any;
  carTypeCode: string | any;
  sasToken: string;
  // Set Info
}

interface IFile {
  uid: string;
  name: string;
  status: string;
  file: any;
  fileName?: string;
  photoUrl?: string;
  preview?: string;
}

type TCarSide = "front" | "back" | "left" | "right";

const partsMap = [1, 2, 3, 4];

const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

function filterByUID(
  arr1: Array<{ file: { uid: string } }>,
  arr2: Array<{ uid: string }>
): Array<{ uid: string }> {
  return arr2.filter((item2) =>
    arr1.some((item1) => item1.file.uid === item2.uid)
  );
}

function findByFilePosition(array: any, filePosition: any) {
  return array.find((item: any) => item.filePosition === filePosition);
}

const sideImages = {
  AUTOS: {
    right: carRight,
    left: carLeft,
    front: carFront,
    back: carBack,
  },
  "VEHICULOS DE CARGA LIGERA": {
    right: vanRight,
    left: vanLeft,
    front: vanFront,
    back: vanBack,
  },
  MOTOCICLETA: {
    right: bikeRight,
    left: bikeLeft,
    front: bikeFront,
    back: bikeBack,
  },
};

const RadioCarParts: FC<{
  carTypeCode: string;
  carSide: TCarSide;
  setCarSide: Function;
  vehicleFace: number;
  setVehicleFace: Function;
}> = ({ carTypeCode, carSide, setCarSide, vehicleFace, setVehicleFace }) => {
  const titleText = () => {
    switch (vehicleFace) {
      case 1:
        return "Frente";
      case 2:
        return "Lado derecho";
      case 3:
        return "Atrás";
      case 4:
        return "Lado izquierdo";
      default:
        return "Error";
    }
  };

  return (
    <div className="car-part-col">
      <p className="blue-title">{titleText()}</p>
      <div className="car-part">
        <LeftOutlined
          color="red"
          onClick={() => {
            if (vehicleFace > 1) setVehicleFace((prev: number) => prev - 1);
          }}
        />
        <Radio.Group
          className="car-radio-part"
          value={vehicleFace}
          onChange={(e) => setVehicleFace(e.target.value)}
          defaultValue={vehicleFace}
        >
          <Radio.Button value={1}>
            <img src={sideImages[carTypeCode]["front"]} />
          </Radio.Button>
          <Radio.Button value={2}>
            <img src={sideImages[carTypeCode]["right"]} />
          </Radio.Button>
          <Radio.Button value={3}>
            <img src={sideImages[carTypeCode]["back"]} />
          </Radio.Button>
          <Radio.Button value={4}>
            <img src={sideImages[carTypeCode]["left"]} />
          </Radio.Button>
        </Radio.Group>
        <RightOutlined
          onClick={() => {
            if (vehicleFace < 4) setVehicleFace((prev: number) => prev + 1);
          }}
        />
      </div>
    </div>
  );
};

export const WillWash: FC<any> = ({
  WillWashForm,
  // Controll actions
  nextSlide,
  prevSlide,
  // isReadOnly
  isReadOnly,
  // Custom
  title,
  next,
  back,
  setIsWash,
  // Info
  isOwn,
  currentStep,
}) => {
  const completeForm = (data: any) => {
    nextSlide(next);
  };

  const isSubmitDisabled = () => {
    return true;
  };

  return (
    <div className="physical-details-container">
      <Form
        form={WillWashForm}
        initialValues={{
          cleanService: WillWashForm.getFieldValue("cleanService"),
          ...WillWashForm.getFieldsValue(),
        }}
        onFinish={(e) => completeForm(e)}
        onReset={() => prevSlide(back)}
      >
        <div className="reception-step-resize">
          <div className="sub-reception-step">
            <p className="bold title">{title}</p>

            <div className="dt-sub-container no-color">
              <Form.Item
                className="class-formatType"
                name="cleanService"
                rules={[
                  {
                    required: isReadOnly ? false : true,
                    message: "Es necesario indicar si se lavara el vehículo",
                  },
                ]}
                extra="¿Se lavará el vehículo?"
              >
                <Radio.Group
                  disabled={isReadOnly}
                  onChange={(e) => {
                    if (setIsWash) setIsWash(e.target.value);
                  }}
                >
                  <Radio.Button value={true}>Sí</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          {FooterRender(isOwn, currentStep, isReadOnly, isSubmitDisabled)}
        </div>
      </Form>
    </div>
  );
};

export const PhysicalDetails: FC<IPhysicalDetails> = ({
  PhysicalDetailsForm,
  // Controll actions
  nextSlide,
  prevSlide,
  // isReadOnly
  isReadOnly,
  // Custom
  title,
  next,
  back,
  willWash,
  isWash,
  setIsWash,
  // Info
  isOwn,
  workshopId,
  currentStep,
  appointmentTracking,
  carTypeCode,
  sasToken,
}) => {
  const [carSide, setCarSide] = useState<TCarSide>("front");

  const [vehicleFace, setVehicleFace] = useState<number>(1);
  const [uploadList, setUploadList] = useState<any[]>([[], [], [], []]);

  const updateArray = useCallback((element) => {
    let copy = [...uploadList];
    copy = element;
    setUploadList(copy);
    // form.setFieldValue("carkerDocuments", [...element]);
  }, []);

  const completeForm = async (data: any) => {
    await updateAttForm();
    nextSlide(next);
  };

  const updateAttForm = async () => {
    const uploadTemp = [...uploadList];

    await uploadTemp.map(async (list: any[], index: number) => {
      const docs = PhysicalDetailsForm.getFieldValue(`carPart_${index}`);

      if (docs && docs.fileList) {
        const result = {
          file: docs.file,
          fileList: await filterByUID(uploadTemp[index], docs.fileList),
        };

        PhysicalDetailsForm.setFieldValue(`carPart_${index}`, result);
      }
    });
  };

  const isSubmitDisabled = () => {
    return true;
  };

  const getMin = () => {
    switch (vehicleFace) {
      case 1:
        return 15;
      case 2:
        return 20;
      case 3:
        return 25;
      case 2:
        return 30;
      default:
        return 0;
    }
  };

  const getMax = () => {
    switch (vehicleFace) {
      case 1:
        return 19;
      case 2:
        return 24;
      case 3:
        return 29;
      case 2:
        return 34;
      default:
        return 0;
    }
  };

  const uploadProps: TUploadProps = {
    name: "file",
    showUploadList: false,
    multiple: false,
    accept: "image/*",
    beforeUpload: async (file: RcFile): Promise<any> => {
      if (!file.type.startsWith("image/")) {
        CkMessage({type: "error", text: "Solo se soportan imágenes."});
        return Upload.LIST_IGNORE;
      }

      const fileSizeInMB = file.size / 1024 / 1024;

      if (fileSizeInMB > 21) {
        CkMessage({type: "error", text: "El archivo excede el límite de 20MB."});
        return Upload.LIST_IGNORE;
      }

      if (uploadList[vehicleFace - 1].length == 5) {
        CkMessage({
          type: "error",
          text: "El máximo de archivos adjuntos para esta sección es 5"
        });
        return Upload.LIST_IGNORE;
      }

      const preview = await getBase64(file);
      let tempArray = [...uploadList];
      const item = [...uploadList[vehicleFace - 1]];
      item.push({
        uid: item.length.toString(),
        name: file.name,
        status: "done",
        file: file,
        preview: preview,
      });
      tempArray[vehicleFace - 1] = [...item];
      updateArray([...tempArray]);
    },
  };

  const FilePreview: FC<{
    file: IFile;
    // fileType: "image" | "video";
    fileType: string;
    index: number;
  }> = ({ file, fileType, index }) => {
    const deleteFile = () => {
      let selectedCard = [...uploadList];
      selectedCard[vehicleFace - 1] = selectedCard[vehicleFace - 1].filter(
        (f: any) => f.uid !== file.uid
      );
      updateArray(selectedCard);

      let docs = PhysicalDetailsForm.getFieldValue([
        `carPart_${vehicleFace - 1}`,
        "fileList",
      ]);
      docs = docs.filter((f: any) => f.uid !== file.file.uid);

      if (docs.length > 0) {
        PhysicalDetailsForm.setFieldValue(`carPart_${vehicleFace - 1}`, {
          file: {},
          fileList: docs,
        });
      } else {
        PhysicalDetailsForm.setFieldValue(
          [`carPart_${vehicleFace - 1}`, "fileList"],
          []
        );
      }
    };
    return (
      <figure className={`reception-document-preview ${fileType}`}>
        {fileType.startsWith("image/") && (
          <ImageView
            className="document-view"
            src={file.preview}
            preview={{
              zIndex: 6000,
            }}
          />
        )}
        {fileType === "application/pdf" && (
          <iframe
            className="document-view-pdf"
            src={file.preview}
            style={{ width: "100%", height: "100%" }}
            frameBorder={1}
          />
        )}
        <TrashIcon
          className="trashico-white"
          style={{ color: "var(--primary-color)" }}
          onClick={deleteFile}
        />
      </figure>
    );
  };

  return (
    <div className="physical-details-container">
      <Form
        form={PhysicalDetailsForm}
        initialValues={{
          carFileList: [],
          ...PhysicalDetailsForm.getFieldsValue(),
        }}
        onFinish={(e) => completeForm(e)}
        onReset={() => prevSlide(back)}
      >
        <div className="reception-step-resize">
          <div className="sub-reception-step">
            <p className="bold title">{title}</p>

            <div className="dt-sub-container">
              <RadioCarParts
                carTypeCode={carTypeCode}
                carSide={carSide}
                setCarSide={setCarSide}
                vehicleFace={vehicleFace}
                setVehicleFace={setVehicleFace}
              />

              <p
                className="regular"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "10px 0px 0px 0px",
                }}
              >
                Puedes cargar <strong>hasta 5 fotos</strong> de los detalles que
                encuentres del vehículo o señalarlos en el diagrama.
              </p>

              {partsMap.map((count, index) => {
                return (
                  <>
                    {!isReadOnly && (
                      <Form.Item
                        className={[
                          ...["class-formatCarkerDoc"],
                          uploadList[vehicleFace - 1].length < 8
                            ? ["resize"]
                            : [],
                          index != vehicleFace - 1 ? ["hide-form"] : [],
                        ].join(" ")}
                        name={`carPart_${vehicleFace - 1}`}
                      >
                        {index == vehicleFace - 1 &&
                          uploadList[vehicleFace - 1].length < 8 && (
                            <Upload
                              disabled={isReadOnly}
                              {...uploadProps}
                              className={[
                                ...["upload-document"],
                                uploadList[vehicleFace - 1].length < 8
                                  ? ["transparent"]
                                  : [],
                              ].join(" ")}
                            >
                              <Button
                                disabled={isReadOnly}
                                className="upload-botton"
                                icon={<PictureOutlined />}
                              >
                                Adjuntar fotos
                              </Button>
                            </Upload>
                          )}
                      </Form.Item>
                    )}

                    {!isReadOnly &&
                      index == vehicleFace - 1 &&
                      uploadList &&
                      uploadList[vehicleFace - 1].length > 0 && (
                        <div className="reception-multiple-document">
                          {!isReadOnly &&
                            uploadList &&
                            uploadList[vehicleFace - 1] &&
                            uploadList[vehicleFace - 1].map(
                              (file: any, idx: number) => (
                                <FilePreview
                                  file={file}
                                  fileType={file.file.type}
                                  index={idx}
                                  key={`file-preview-${idx}`}
                                />
                              )
                            )}
                        </div>
                      )}

                    {isReadOnly && index == vehicleFace - 1 && (
                      <div className="reception-multiple-document">
                        {isReadOnly &&
                          appointmentTracking.trackingPhotos
                            .filter(
                              (item: any) =>
                                item.filePosition >= getMin() &&
                                item.filePosition <= getMax()
                            )
                            .map((item: any) => (
                              <ImageView
                                rootClassName="readonly-image-small"
                                src={`${item.photoUrl || ""}?${sasToken}`}
                                preview={{
                                  zIndex: 6000,
                                }}
                              />
                            ))}
                      </div>
                    )}

                    {index == vehicleFace - 1 && (
                      <div className="images-rules">
                        <p className="regular" style={{ margin: "0px" }}>
                          <strong>Formatos permitidos:</strong> Imágen (JPG,
                          PNG) Documento (PDF, .doc, .docx).
                        </p>
                        <p className="regular" style={{ margin: "0px" }}>
                          <strong>Tamaño máximo:</strong> 20 MB
                        </p>
                      </div>
                    )}
                  </>
                );
              })}

              {!isReadOnly && !willWash && (
                <Canvas
                  PhysicalDetailsForm={PhysicalDetailsForm}
                  carTypeCode={carTypeCode}
                  carSide={carSide}
                  setCarSide={setCarSide}
                  vehicleFace={vehicleFace}
                  currentStep={currentStep}
                />
              )}

              {isReadOnly &&
                isOwn == "carker" &&
                appointmentTracking &&
                appointmentTracking.trackingPhotos.length > 0 && (
                  <div className="readonly-col-center">
                    {vehicleFace === 1 && (
                      <ImageView
                        rootClassName="readonly-image"
                        src={`${
                          findByFilePosition(
                            appointmentTracking.trackingPhotos,
                            3
                          ).photoUrl || ""
                        }?${sasToken}`}
                        preview={{
                          zIndex: 6000,
                        }}
                      />
                    )}
                    {vehicleFace === 2 && (
                      <ImageView
                        rootClassName="readonly-image"
                        src={`${
                          findByFilePosition(
                            appointmentTracking.trackingPhotos,
                            1
                          ).photoUrl || ""
                        }?${sasToken}`}
                        preview={{
                          zIndex: 6000,
                        }}
                      />
                    )}
                    {vehicleFace === 3 && (
                      <ImageView
                        rootClassName="readonly-image"
                        src={`${
                          findByFilePosition(
                            appointmentTracking.trackingPhotos,
                            4
                          ).photoUrl || ""
                        }?${sasToken}`}
                        preview={{
                          zIndex: 6000,
                        }}
                      />
                    )}
                    {vehicleFace === 4 && (
                      <ImageView
                        rootClassName="readonly-image"
                        src={`${
                          findByFilePosition(
                            appointmentTracking.trackingPhotos,
                            2
                          ).photoUrl || ""
                        }?${sasToken}`}
                        preview={{
                          zIndex: 6000,
                        }}
                      />
                    )}
                  </div>
                )}

              <Form.Item
                style={{ height: "1px", padding: "0px", margin: "0px" }}
                name={"carFileList"}
              ></Form.Item>
              <Form.Item
                style={{ height: "1px", padding: "0px", margin: "0px" }}
                name={["carPart_0", "fileList"]}
              ></Form.Item>
              <Form.Item
                style={{ height: "1px", padding: "0px", margin: "0px" }}
                name={["carPart_1", "fileList"]}
              ></Form.Item>
              <Form.Item
                style={{ height: "1px", padding: "0px", margin: "0px" }}
                name={["carPart_2", "fileList"]}
              ></Form.Item>
              <Form.Item
                style={{ height: "1px", padding: "0px", margin: "0px" }}
                name={["carPart_3", "fileList"]}
              ></Form.Item>
            </div>
          </div>

          {FooterRender(isOwn, currentStep, isReadOnly, isSubmitDisabled)}
        </div>
      </Form>
    </div>
  );
};
